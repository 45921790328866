import React, { FunctionComponent, memo } from 'react';

import './CustomFooter.scss';
import { texts } from '../texts';

export const CustomFooter: FunctionComponent = memo(() => (
  <footer id={'footer'} className="page-footer blue font-small footer">
    <div className="footer-copyright text-center" style={{color: 'white'}}>
      &copy; {(new Date().getFullYear())} Copyright:
      <a href={texts.COPYRIGHT_URL} target="_blank" rel="noopener"
         style={{paddingLeft: '.3rem'}}>{texts.COPYRIGHT_TEXT}</a>
    </div>
  </footer>
));
