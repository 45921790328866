import React, { PureComponent, Fragment } from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';

import SessionComponent from '../components/SessionComponent';
import LogOutButton from '../components/LogOutButton';
import { OutsideAlerter } from '../components/OutsideAlerter';
import { NavDropdown, NavLinkItem } from './NavDropdown';
import { SessionState } from '../session.state';
import { texts } from '../texts';
import { ReduxState } from '../redux/store';
import { menuItems } from '../global.state';
import { AppContextFactory } from '../context';

import './Header.scss';
import { app } from '../../global';
import {TextResource} from '../TextResource';

interface State {
  open: boolean;
  isWideEnough: boolean;
}

interface Props {
  getContext: AppContextFactory;
}

interface InjectedProps extends Props {
  session: SessionState;
}

export class HeaderImpl extends PureComponent<Props, State> {

  state = {
    open: false,
    isWideEnough: false,
  };

  nonNavigationClick = () => {
    this.setState({open: false});
  };

  onNavLinkClick = () => {
    this.setState({open: false});
  };

  onToggleClick = () => {
    this.setState(({open}) => ({open: !open}));
  };

  render() {

    const injectedProps = this.props as InjectedProps;
    const session = injectedProps.session;

    return (
      <OutsideAlerter registered={this.state.open} onOutsideClick={this.nonNavigationClick}>
        {(ref: any) => (<nav id={'header'} className="navbar navbar-dark fixed-top navbar-expand-md blue" ref={ref}>

          <NavLink className="brand-name" to="/" onClick={this.onNavLinkClick}>
          <TextResource injectHtml resourceId={27} defaultValue={texts.APP_NAME} minimumLength={3}></TextResource>
          </NavLink>

          {!this.state.isWideEnough &&
          <button type="button" aria-label="Navbar Toggler" className="navbar-toggler" onClick={this.onToggleClick}>
            <span className="navbar-toggler-icon"/>
          </button>}

          <div className={`collapse navbar-collapse ${(this.state.open ? 'show' : '')}`}>

            <ul className="navbar-nav mr-auto">

              <SessionComponent role="patient" feature="PatientAppointmentPage">
                <li className="nav-item">
                  <NavLink
                    to={'/patient/appointment'} className="nav-link"
                    onClick={this.onNavLinkClick}>
                    Запази час
                  </NavLink>
                </li>
              </SessionComponent>

              <SessionComponent role="doctor" feature="DoctorAppointmentPage">
                <li className="nav-item">
                  <NavLink
                    to={'/doctor/appointment'} className="nav-link"
                    onClick={this.onNavLinkClick}>
                    Запази час
                  </NavLink>
                </li>
              </SessionComponent>

              <SessionComponent publicOnly feature="AppointmentPage">
                <li className="nav-item">
                  <NavLink
                    to={'/public/appointment'} className="nav-link"
                    onClick={this.onNavLinkClick}>
                    Запази час
                  </NavLink>
                </li>
              </SessionComponent>

              <SessionComponent publicOnly feature="PatientRegisterPage">
                <li className="nav-item">
                  <NavLink
                    to={'/register'} className="nav-link"
                    onClick={this.onNavLinkClick}>
                    Регистрация на пациент
                  </NavLink>
                </li>
              </SessionComponent>

              <NavDropdown text="Меню" onLinkClick={this.onNavLinkClick} items={menuItems}/>

              {(() => {
                if (process.env.NODE_ENV === 'development') {

                  const demoLinks: NavLinkItem[] = [
                    {link: '/demo/text-field', name: 'TextField'},
                    {link: '/demo/number-field', name: 'NumberField'},
                    {link: '/demo/text-area-field', name: 'TextAreaField'},
                    {link: '/demo/editor-field', name: 'EditorField'},
                    {link: '/demo/select-field', name: 'SelectField'},
                    {link: '/demo/multi-select-field', name: 'MultiSelectField'},
                    {link: '/demo/list-multi-select-field', name: 'ListMultiSelectField'},
                    {link: '/demo/date-field', name: 'DateField'},
                    {link: '/demo/checkbox-field', name: 'CheckboxField'},
                    {link: '/demo/file-select-field', name: 'FileSelectField'},
                    {link: '/demo/pdf-draw', name: 'PdfDraw'},
                  ];

                  return (
                    <Fragment>
                      <NavDropdown text="Demo" onLinkClick={this.onNavLinkClick} items={demoLinks}/>
                      <li className="nav-item">
                        <div
                          style={{cursor: 'pointer'}}
                          className="nav-link"
                          onClick={() => {
                            const {actions} = app.getContext();
                            actions.cache.updateCache(true);
                            this.onNavLinkClick();
                          }}>
                          Обнови номен.
                        </div>
                      </li>
                    </Fragment>
                  );
                }
                return null;
              })()}
            </ul>

            <ul className="navbar-nav ml-auto">

              <li className="header-username nav-item">{session.userDisplayName}</li>

              <li className="nav-item">
                <SessionComponent>
                  <LogOutButton render={(props) =>
                    <a className="nav-link" {...props}>{texts.NAVBAR_LOGOUT_BUTTON}</a>
                  }
                  />
                </SessionComponent>
              </li>
              <li className="nav-item">
                <SessionComponent publicOnly>
                  <NavLink
                    to={'/login'}
                    className="nav-link"
                    onClick={this.onNavLinkClick}>
                    {texts.NAVBAR_LOGIN_BUTTON}
                  </NavLink>
                </SessionComponent>
              </li>
            </ul>

          </div>
        </nav>)}
      </OutsideAlerter>
    );
  }
}

export const Header = connect(({session, cache}: ReduxState) => ({session, cache}), null)(HeaderImpl);
