/* tslint:disable:max-classes-per-file */
import {SigningElement} from '../../dto';

class PageDraw {
  canvasElement: HTMLCanvasElement | null = null;
  signingElement: HTMLDivElement | null = null;
  touched: boolean = false;
  isDocumentSignable: boolean = false;
  pageIndex: number = -1;
  drawing: boolean = false;
  currentMouseX: number = 0;
  currentMouseY: number = 0;
  previousMouseX: number = 0;
  previousMouseY: number = 0;
  isEnabled: boolean = false;
  onFirstTouched: (() => void) | undefined;

  setPageRef = (pageRef: HTMLDivElement, signingElement: SigningElement | undefined) => {
    let canvasElement = pageRef.querySelector('[data-draw-canvas]') as HTMLCanvasElement;

    if (!canvasElement) {
      const newCanvasElement = document.createElement('canvas');
      newCanvasElement.setAttribute('data-draw-canvas', '');
      newCanvasElement.innerText = 'Browser not supported.';

      canvasElement = newCanvasElement;
      pageRef.prepend(canvasElement);
    }

    if (this.canvasElement) {
      const pdfCanvas = pageRef.querySelector('.react-pdf__Page__canvas') as HTMLCanvasElement;
      if (pdfCanvas) {
        const pdfCanvasStyle = window.getComputedStyle(pdfCanvas);
        const width = pdfCanvasStyle.getPropertyValue('width');
        const height = pdfCanvasStyle.getPropertyValue('height');
        let style = `position: absolute; top: 50%; left: 50%; width: ${width}; height: calc(${height} + 0.94px);`;
        style += 'color: transparent; transform: translate(-50%, -50%) rotate(0deg); border-right: 10px solid #7F7FFF';
        this.canvasElement.setAttribute('style', style);
        this.canvasElement.setAttribute('width', width);
        this.canvasElement.setAttribute('height', height);

        if (signingElement) {
          const signingDiv = document.createElement('div');
          let signingDivStyle = `border: 2px dashed red;  position: absolute; z-index: 2;`;
          signingDivStyle += ` width: ${signingElement.signingElementWidth}%; height: ${signingElement.signingElementHeight}%;`;
          signingDivStyle += `top: ${signingElement.signingElementTop}%; left: ${signingElement.signingElementLeft}%;`;
          signingDiv.setAttribute('style', signingDivStyle);
          this.signingElement = signingDiv;
          pageRef.prepend(signingDiv);
        }
      }

      if (this.canvasElement === canvasElement) {
        return;
      } else {
        this.disable();
      }
    }

    this.canvasElement = canvasElement;

    const canvasContext = canvasElement.getContext('2d') as CanvasRenderingContext2D;
    canvasContext.strokeStyle = '#000';
    canvasContext.lineWidth = 1;
  };

  startDrawing = (left: number, top: number) => {
    this.previousMouseX = left;
    this.previousMouseY = top;
    this.currentMouseX = left;
    this.currentMouseY = top;
    this.drawing = true;

    if (!this.touched && this.onFirstTouched) {
      this.onFirstTouched();
    }

    this.touched = true;
  };

  stopDrawing = () => this.drawing = false;

  setDrawingPosition = (left: number, top: number) => {
    this.currentMouseX = left;
    this.currentMouseY = top;
  };

  renderCanvas = () => {
    if (!this.canvasElement) {
      return;
    }

    if (this.drawing) {

      const canvasContext = this.canvasElement.getContext('2d') as CanvasRenderingContext2D;
      const canvasRect = this.canvasElement.getBoundingClientRect();

      canvasContext.moveTo(this.previousMouseX - canvasRect.left, this.previousMouseY - canvasRect.top);
      canvasContext.lineTo(this.currentMouseX - canvasRect.left, this.currentMouseY - canvasRect.top);
      canvasContext.stroke();

      this.previousMouseX = this.currentMouseX;
      this.previousMouseY = this.currentMouseY;
    }

    if (this.isEnabled) {
      requestAnimationFrame(this.renderCanvas);
    }
  };

  onMouseDown = (ev: MouseEvent) => this.startDrawing(ev.clientX, ev.clientY);
  onMouseUp = () => this.stopDrawing();
  onMouseMove = (ev: MouseEvent) => this.setDrawingPosition(ev.clientX, ev.clientY);

  onTouchStart = (ev: TouchEvent) => {
    ev.preventDefault();
    this.startDrawing(ev.touches[0].clientX, ev.touches[0].clientY);
  };
  onTouchEnd = (ev: TouchEvent) => {
    ev.preventDefault();
    return this.stopDrawing();
  };
  onTouchMove = (ev: TouchEvent) => {
    ev.preventDefault();
    const touchX = ev.touches[0].clientX;
    const touchY = ev.touches[0].clientY;

    if (this.signingElement) {
      const signingElementDimensions = this.signingElement.getBoundingClientRect();
      if (touchX < signingElementDimensions.left || touchX > signingElementDimensions.right ||
          touchY < signingElementDimensions.top || touchY > signingElementDimensions.bottom) {
        return;
      }
    }

    this.setDrawingPosition(touchX, touchY);
  };

  enable = () => {
    if (this.signingElement) {
      this.signingElement.addEventListener('mousedown', this.onMouseDown, false);
      this.signingElement.addEventListener('mouseup', this.onMouseUp, false);
      this.signingElement.addEventListener('mouseleave', this.onMouseUp, false);
      this.signingElement.addEventListener('mousemove', this.onMouseMove, false);

      this.signingElement.addEventListener('touchstart', this.onTouchStart, false);
      this.signingElement.addEventListener('touchend', this.onTouchEnd, false);
      this.signingElement.addEventListener('touchmove', this.onTouchMove, false);
    } else if (this.canvasElement && !this.isDocumentSignable) {
      this.canvasElement.addEventListener('mousedown', this.onMouseDown, false);
      this.canvasElement.addEventListener('mouseup', this.onMouseUp, false);
      this.canvasElement.addEventListener('mouseleave', this.onMouseUp, false);
      this.canvasElement.addEventListener('mousemove', this.onMouseMove, false);

      this.canvasElement.addEventListener('touchstart', this.onTouchStart, false);
      this.canvasElement.addEventListener('touchend', this.onTouchEnd, false);
      this.canvasElement.addEventListener('touchmove', this.onTouchMove, false);
    }

    this.isEnabled = true;

    requestAnimationFrame(this.renderCanvas);
  };

  disable = () => {
    if (this.signingElement) {
      this.signingElement.removeEventListener('mousedown', this.onMouseDown, false);
      this.signingElement.removeEventListener('mouseup', this.onMouseUp, false);
      this.signingElement.removeEventListener('mouseleave', this.onMouseUp, false);
      this.signingElement.removeEventListener('mousemove', this.onMouseMove, false);

      this.signingElement.removeEventListener('touchstart', this.onTouchStart, false);
      this.signingElement.removeEventListener('touchend', this.onTouchEnd, false);
      this.signingElement.removeEventListener('touchmove', this.onTouchMove, false);
    }
    if (this.canvasElement) {
      this.canvasElement.removeEventListener('mousedown', this.onMouseDown, false);
      this.canvasElement.removeEventListener('mouseup', this.onMouseUp, false);
      this.canvasElement.removeEventListener('mouseleave', this.onMouseUp, false);
      this.canvasElement.removeEventListener('mousemove', this.onMouseMove, false);

      this.canvasElement.removeEventListener('touchstart', this.onTouchStart, false);
      this.canvasElement.removeEventListener('touchend', this.onTouchEnd, false);
      this.canvasElement.removeEventListener('touchmove', this.onTouchMove, false);
    }

    this.isEnabled = false;
  };

  enableWithImage = (imageData: string) => {
    this.enable();

    if (this.canvasElement) {
      const canvasContext = this.canvasElement.getContext('2d') as CanvasRenderingContext2D;
      const image = new Image();
      image.onload = () => {

        if (!this.canvasElement) {
          return;
        }

        const widthOffset = (this.canvasElement.width / 100) * 8;
        const heightOffset = (this.canvasElement.height / 100) * 5;

        canvasContext.drawImage(
          image,
          this.canvasElement.width - image.width - widthOffset,
          this.canvasElement.height - image.height - heightOffset,
        );
      };
      image.src = 'data:image/png;base64,' + imageData;
    }

    if (!this.touched && this.onFirstTouched) {
      this.onFirstTouched();
    }

    this.touched = true;
  };

  drawFooterText = (renderText: string) => {
    if (this.canvasElement) {
      const canvasContext = this.canvasElement.getContext('2d') as CanvasRenderingContext2D;

      canvasContext.fillStyle = 'black';

      const fontSize = 16;
      canvasContext.font = `${fontSize}px monospace`;

      canvasContext.fillText(
        renderText,
        (this.canvasElement.width / 2) - (renderText.length * (fontSize / 3.62)),
        this.canvasElement.height - 5,
      );
    }
  };
}

interface DrawResult {
  index: number;
  contents: string;
}

export class DocumentDraw {
  pageDictionary: { [pageKey: string]: PageDraw } = {};
  lastPageIndex: number = -1;
  touched: boolean = false;
  signedText: string = '';
  signingElements: SigningElement[] = [];

  pages: PageDraw[] = [];
  lastPage: PageDraw | undefined = undefined;

  setRef = (pageRef: HTMLDivElement, index: number, lastPage: boolean) => {

    if (!pageRef) {
      return;
    }

    const pageKey = `page_${index}`;
    let page = this.pageDictionary[pageKey];

    if (!page) {
      page = new PageDraw();
      page.pageIndex = index;
      page.onFirstTouched = this.onPageTouched;

      this.pageDictionary[pageKey] = page;

      if (index > this.lastPageIndex) {
        this.lastPageIndex = index;
        this.lastPage = page;
      }
    }

    let signingElement;
    if (lastPage) {
      signingElement = this.signingElements.find((x) => x.pageNumber === 5000);
    } else {
      signingElement = this.signingElements.find((x) => x.pageNumber === (index + 1));
    }

    page.isDocumentSignable = this.signingElements.length > 0;

    page.setPageRef(pageRef, signingElement);

    this.pages = Object.values(this.pageDictionary);
  };

  disableAll = () => {
    for (const page of this.pages) {
      page.disable();
    }
  };

  enableAll = () => {
    for (const page of this.pages) {
      page.enable();
    }
  };

  enableAllWithImage = (imageData: string) => {
    for (const page of this.pages) {
      if (page === this.lastPage) {
        page.enableWithImage(imageData);
      } else {
        page.enable();
      }
    }
  };

  onPageTouched = () => {
    if (this.touched) {
      return;
    }

    if (this.lastPage) {
      this.lastPage.drawFooterText(this.signedText);
    }

    this.touched = true;
  };

  getDrawingResult = (): DrawResult[] => {
    const images: DrawResult[] = [];
    for (const x of this.pages) {
      if (x.touched && x.canvasElement) {
        images.push({
          index: x.pageIndex,
          contents: x.canvasElement.toDataURL(),
        });
      }
    }
    return images;
  };
}
