/* tslint:disable:max-classes-per-file */
import React from 'react';
import {RouteComponentProps} from 'react-router-dom';

import {BaseComponent} from '../../infrastructure/components/BaseComponent';
import {AppContextProps} from '../../infrastructure/react-context';
import {DocumentTitle} from '../../infrastructure/DocumentTitle';
import {SignableDocumentDto} from '../../dto';
import {PdfDocument} from '../../infrastructure/components/PdfDocument';
import {base64ToArray} from '../../infrastructure/util';
import {DocumentDraw} from './DocumentDraw';
import {ConfirmModalButton} from '../../common/ConfirmModal/ConfirmModalButton';
import {ErrorMessages} from '../../infrastructure/errors';

interface RouteParams {
  documentID: string;
}

interface Props extends RouteComponentProps<RouteParams>, AppContextProps {
}

interface State {
  documentID: number | undefined;
  model: SignableDocumentDto | undefined;
  documentBytes: Uint8Array | undefined;
  documentStatus: number | undefined;
  pageWidth: number;

  loading: boolean;
  errorMessages: string[];
}

export class SignDocument extends BaseComponent<Props, State> {
  state: State = {
    documentID: undefined,
    model: undefined,
    documentBytes: undefined,
    documentStatus: undefined,
    pageWidth: 0,

    loading: true,
    errorMessages: [],
  };

  documentDraw = new DocumentDraw();

  componentDidMountAsync = async () => {
    const {server, actions} = this.props.context;

    const documentID = Number.parseInt(this.props.match.params.documentID, 10);
    await this.setStateAsync({documentID});

    await this.setSignableDocumentStatus(4, false);

    const response = await server.getSignableDocument({documentID});
    if (!response.success) {
      actions.errors.setErrorMessages(response.errorMessages);
      await this.setStateAsync({loading: false});
      return;
    }

    this.documentDraw.signedText = response.payload.item.signedText;
    this.documentDraw.signingElements = response.payload.signingElement;

    await this.setStateAsync({
      model: response.payload.item,
      documentBytes: base64ToArray(response.payload.item.documentContent),
      pageWidth: response.payload.pageWidth,
      loading: false,
    });
  };

  setSignableDocumentStatus = async (status: number, shouldRedirect: boolean) => {
    const documentID = this.state.documentID;
    if (!documentID) {
      return;
    }

    const {server, actions} = this.props.context;

    const response = await server.setSignableDocumentStatus({documentID, status});
    if (!response.success) {
      actions.errors.setErrorMessages(response.errorMessages);
      return;
    }

    await this.setStateAsync({documentStatus: status});

    if (shouldRedirect) {
      actions.router.routerPush('/doctor/document-signing');
    }
  };

  componentWillUnmountAsync = async () => {
    if (!this.state.documentStatus) {
      await this.setSignableDocumentStatus(3, false);
    }
  };

  onSave = async () => {
    const documentID = this.state.documentID;
    if (!documentID) {
      return;
    }

    const {server, actions} = this.props.context;

    const images = this.documentDraw.getDrawingResult();
    const response = await server.saveSignableDocument({
      images,
      documentID,
      userPassword: '',
    });

    if (!response.success) {
      this.setState({errorMessages: response.errorMessages});
      return;
    }

    actions.router.routerPush('/doctor/document-signing');
  };

  onLoad = (lastPage: boolean) => {
    if (lastPage) {
      this.documentDraw.enableAll();
    }
  };

  signWithSavedSignature = async () => {
    const {server, actions} = this.props.context;
    await this.setStateAsync({loading: true});

    const response = await server.getDoctorSignature({});

    if (!response.success) {
      actions.errors.setErrorMessages(response.errorMessages);
      await this.setStateAsync({loading: false});
      return;
    }

    this.documentDraw.enableAllWithImage(response.payload.signatureImageContent);

    await this.setStateAsync({loading: false});
  };

  render() {
    const {documentBytes, model, loading, errorMessages} = this.state;

    return (
      <div className="document-signing-form-page">
        <DocumentTitle title={`Подписване на документ ${(model ? ('- ' + model.documentName) : '')}`}/>

        {!loading &&
            <div id={'signing-buttons'} className="d-flex justify-content-end bg-white border mb-1" style={{position: 'sticky', top: '3em', zIndex: 10 }}>
                <button
                    className="btn btn-md btn-green"
                    onClick={this.onSave}>
                    ГОТОВО ПОДПИСАХ СЕ / I HAVE SIGNED /
                </button>

                <ConfirmModalButton
                    onConfirm={async () => await this.setSignableDocumentStatus(3, true)}
                    modalDescription={'Сигурни ли сте, че искате да откажете подписване на този документ?'}
                    btnClassName={'btn-md btn-warning'}
                    btnText={'Отказ / Cancel /'}
                />
            </div>}

          {documentBytes && <PdfDocument
              data={documentBytes}
              pageRef={this.documentDraw.setRef}
              disableTextLayer
              forceCanvasRendering
              onPageLoad={(x) => this.onLoad(x)}
              fitToDisplay={true}
          />}

          <ErrorMessages errors={errorMessages}/>

      </div>
    );
  }
}
